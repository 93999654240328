
import eventBus from '@/utils/event-bus'

export default {
  name : 'BaseLayouts',
  data() {
    return {
      registerOpen : false,
      forgetOpen : false,
      loginOpen : false,
      isToBack : false,
      navShow : false
    }
  },
  computed : {},
  watch : {
    '$route' : {
      handler() {
        // console.log(3242342,this.loading)
        // this.renderLoading()
      },
      immediate : true,
      deep : true
    }
  },
  created() {
    this.renderLoading()
  },
  mounted() {
    eventBus.$on( 'loginOrRegister', k => {
      if ( k == 'openRegister' ) {
        this.registerOpen = true
        this.isToBack = false
      }
      if ( k == 'closeRegister' ) {
        this.registerOpen = false
        this.isToBack = false
      }
      if ( k == 'openLogin' ) {
        this.loginOpen = true
        this.isToBack = false
      }
      if ( k == 'openLoginBack' ) {
        this.loginOpen = true
        this.isToBack = true
      }
      if ( k == 'closeLogin' ) {
        this.loginOpen = false
        this.isToBack = false
      }

      if ( k == 'openForget' ) {
        this.forgetOpen = true
        this.isToBack = false
      }

      if ( k == 'closeForget' ) {
        this.forgetOpen = false
        this.isToBack = false
      }
    } )
  },
  methods : {

    renderLoading() {
    },
    openRegister() {

    },
    closedRegister() {
      eventBus.$emit( 'loginOrRegister', 'closedRegister' )
    },
    openLogin() {

    },
    closedLogin() {
      eventBus.$emit( 'loginOrRegister', 'closedLogin' )
    },
    loginOver() {
      eventBus.$emit( 'loginOver', true )
    },
    closedForget() {
      eventBus.$emit( 'loginOrRegister', 'closedForget' )
    },
    openForget() {

    }
  }
}
