
export default {
  data() {
    return {}
  },
  computed : {},
  // 设置head信息
  head() {
    return {
      link : [
        {
          rel : 'icon',
          type : 'image/x-icon',
          href : ''
        }
      ]
    }
  }
}
