import { render, staticRenderFns } from "./base.vue?vue&type=template&id=3cfdacee&scoped=true"
import script from "./base.vue?vue&type=script&lang=js"
export * from "./base.vue?vue&type=script&lang=js"
import style0 from "./base.vue?vue&type=style&index=0&id=3cfdacee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cfdacee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavBar: require('/app/components/NavBar/index.vue').default,Footer: require('/app/components/Footer/index.vue').default,LoAndReRegister: require('/app/components/LoAndRe/register.vue').default,LoAndReLogin: require('/app/components/LoAndRe/login.vue').default,LoAndReForget: require('/app/components/LoAndRe/forget.vue').default})
